import * as React from 'react';

import Logo from '../../images/logo.png';

import './Header.scss';


const Header = () => {
    return (
        <div className='header'>
            <img src={Logo} alt="CompanionShape Logo" />  
        </div>
    )
}

export default Header;