import React, { useEffect, useState } from 'react';

import './CountDown.scss';


const CountDown = () => {
    const [date, setDate] = useState({
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
      });
    
      let countDownInterval = null;
    
      useEffect(() => {
        countDownTimer();
      });
    
      useEffect(() => {
        return function cleanup() {
          clearInterval(countDownInterval);
        };
      });
    
      const countDownTimer = () => {
        let releaseDate = new Date("Sep 30, 2022 00:00:00").getTime();
        countDownInterval = setInterval(() => {
          let now = new Date().getTime();
    
          let duration = releaseDate - now;
    
          if (duration < 0) {
            setDate({
              days: "00",
              hours: "00",
              minutes: "00",
              seconds: "00",
            });
    
            clearInterval(countDownInterval);
            return;
          }
    
          let days = Math.floor(duration / (1000 * 60 * 60 * 24));
          let hours = Math.floor(
            (duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          let mins = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
          let secs = Math.floor((duration % (1000 * 60)) / 1000);
    
          setDate({
            days: days >= 10 ? days : "0" + days,
            hours: hours >= 10 ? hours : "0" + hours,
            minutes: mins >= 10 ? mins : "0" + mins,
            seconds: secs >= 10 ? secs : "0" + secs,
          });
        }, 1000);
      };

      return (
        <div className="countdown-container">
            <h1 className="coming-soon">
                coming <span>30 sep 2022</span>
            </h1>
            <div className="countdown">
                <div className="section">
                    <span>{date.days}</span>
                    <small>days</small>
                </div>
                <div className="section">
                    <span>{date.hours}</span>
                    <small>hours</small>
                </div>
                <div className="section">
                    <span>{date.minutes}</span>
                    <small>min</small>
                </div>
                <div className="section">
                    <span>{date.seconds}</span>
                    <small>sec</small>
                </div>
            </div>
        </div>
      )
}

export default CountDown;