import React from 'react';
import BtnNotified from '../BtnNotified/BtnNotified';

import './Form.scss';


const Form = () =>{
    return(
        <form className='form'>
            <label htmlFor="name"></label>
            <input type="text" name="name" placeholder='Name' />
            <label htmlFor="email"></label>
            <input type="email" name="email" placeholder='Email Address' />
            <label htmlFor="type"></label>
            <select name="type" id="userType" >
                <option value="select" selected>Select User Type</option>
                <option value="regular">Regular User</option>
                <option value="coach">Coach</option>
                <option value="chef">Chef</option>
            </select>
            <label htmlFor="phone"></label>
            <input type="phone" name="phone" placeholder='Phone Number' />
            <label htmlFor="country"></label>
            <input type="text" name="country" placeholder='country' />
            <BtnNotified label="Get on the list"/>
        </form>
    )
}

export default Form;