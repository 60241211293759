import { Link } from 'gatsby';
import * as React from 'react';
import './BtnNotified.scss';

const BtnNotified = ({services, label}) => {
    return(
        <button className={`btn-notified btn-notified__${services}`}>
            {label !== null ? label : <Link className="Link" to='/getNotified'>Get Notified</Link>}
        </button>
    )
}

export default BtnNotified;