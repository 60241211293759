import * as React from 'react';
import BtnNotified from '../BtnNotified/BtnNotified';

import './Intro.scss';

const Intro = ({title, paragraph, showBtn}) => {
    return(
        <div className="intro">
            <h1 className="title">
            {title}
            </h1>
            <p className="paragraph">
            {paragraph}
            </p>
            {showBtn === false ? '' : <BtnNotified />}
        </div>
    )
}

export default Intro;