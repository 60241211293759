import React from 'react';
import CountDown from '../components/CountDown/CountDown';
import Footer from '../components/Footer/Footer';
import Form from '../components/Form/Form';

import Header from '../components/Header/Header';
import Intro from '../components/Intro/Intro';

import '../scss/getNotified.scss';

const GetNotified = () =>{
    return(
        <div className="get-notified">
            <Header />
            <Intro 
                title='Get Early Access Our Solution With Great Perks'
                paragraph='Easily manage your healthy lifestyle. Get on the list and receive in-app perks available only to early subscribers.
                We are moving into final development and getting ready for official launch soon.'
                showBtn={false}
                />
            <CountDown />
            <Footer className="gn-footer">
                <Form/>
            </Footer>
        </div>
    )
}

export default GetNotified;